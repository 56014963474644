@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

@mixin pageWidths {
    position: relative;
    max-width: 408px;
    padding: 0 30px;
    margin: auto;
}

.root {
  min-height: 100vh;
  overflow: auto;

  .desktopOnly {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    background: var(--bg) no-repeat top center;
    background-size: cover;
    background-attachment: fixed;
    
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: var(--bg2x) no-repeat top center;
      background-size: cover;
      background-attachment: fixed;
    }
  }

  .header {
    @include pageWidths;
  }

  hr {
    flex: 1;
    border-bottom: 1px solid $grey-feature;
  }

  .left {
    text-align: left;
  }

  .marginReset {
    margin: 0;
    padding: 0;
  }

  p {
    font-family: $sofiapro;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $pale-navy;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .altHeader {
    @include pageWidths;
    display: flex;
    align-items: center;
    justify-content: center;

    hr {
      flex: 1;
    }

    .diamonds {
      width: 32px;
      height: 16px;
      background: url('/images/icons/diamonds.svg') no-repeat;
      background-size: contain;
      margin: 0 10px;
    }
  }

  .lower {
    @include pageWidths;
  }

  .form {
    @include pageWidths;
  }

  .login {
    margin: 35px auto;
  }

  h1, h2 {
    font-family: $harrybeastregular;
    color: $white;
    font-weight: 700;
    font-size: 30px;
    line-height: 126%;
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-family: $harrybeastdisplay;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
    }
  }

  h3 {
    font-family: $harrybeastregular;
    color: $white;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: left;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      line-height: 32px;
    }

    &.center {
      text-align: center;
    }
  }

  .btnContainer {
    margin: 30px 0;
  }
  
  .centerContent {
    text-align: center;
    h3 {
      text-align: center;
    }
  }

  .label {
    font-family: $sofiapro;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $white;
    margin: 20px 0 12px;
    display: block;
    font-weight: 800;

    &.disabled {
      color: #696B78;
    }
  }

  input:-webkit-autofill, input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px $input-bg2 inset;
    -webkit-text-fill-color: $white;
  }

  ::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      position: absolute;
      right: 0;
  }

  .dynamicErrorText {
    text-align: left;
    color: $form-input-fail;
    font-size: $form-text-large;
    margin-top: -2px;
    display: block;
    @include sofiaRegular();
    line-height: 16px;

    &.pageLevel {
      text-align: center;
      margin-top: 20px;
    }
  }

  .dateInput.hasValue {
    &::-webkit-datetime-edit-text, &::-webkit-datetime-edit-month-field, &::-webkit-datetime-edit-day-field, &::-webkit-datetime-edit-year-field {
      color: white;
    }
  }

  .icon {
    top: 19px;
    right: 10px;

    svg {
      width: 33px;
      height: 35px;
    }

    &:global(.show) {
      cursor: pointer;
    }

    &:global(.hide) {
      cursor: pointer;
      top: 14px;
      right: 8px;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }

  a {
    color: $melrose;
    font-size: 15px;
    line-height: 24px;
    text-decoration: underline;
    font-family: $sofiapro;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  ul.passwordError {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: $sofiapro;
    font-size: 14px;
    line-height: 16px;
    color: $grey-legal-border;

    li {
      padding-left: 30px;
      margin-bottom: 7px;
      position: relative;
    }

    li:before {
      content: '—';
      position: absolute;
      left: 2px;
      top: -2px;
    }

    li.pass {
      color: $white;
      &:before {
        position: absolute;
        content: '';
        background-image: url('/images/icons/pass.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 14px;
        width: 14px;
        left: 0px;
        top: 3px;
      }
    }

    li.fail {
      color: $form-input-fail;
      &:before {
        position: absolute;
        content: '';
        background-image: url('/images/icons/fail.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 12px;
        width: 12px;
        left: 0px;
        top: 3px;
      }
    }
  }

  .half {
    display: flex;

    & > div {
      width: 48%;

      & + div {
        margin-left: 4%;
      }
    }
  }

  .forgotPassword {
    color: $snow;
    transition: 0.1s color ease-in-out;
    &:hover {
      color: $lightMagic;
    }
  }

  .termsAndPrivacy {
    margin: 25px 0;
    font-size: 14px;
    line-height: 20px;
    font-family: $sofiapro;
    color: $grey-legal-dark;

    a {
      font-size: 13px;
      color: $snow;
      text-decoration: underline;

      &:hover {
        color: $lightMagic;
      }
    }
  }

  :global {
    .error-text {
      font-family: $sofiapro;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 5px;
    }

    .datetimebox {
      opacity: 0;
      display: none;
    }
      
    .input-svg {
      &.calendar {
        top: 18px;
        right: 8px;

        svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .bodyText {
    font-size: 16px;
    color: $pale-navy;
    line-height: 26px;
  
    a {
      font-size: 16px;
    }

    &.padded {
      margin-bottom: 32px;
    }
  }
}
